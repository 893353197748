import { ApiTagType, api } from 'services/api';
import z from 'zod';
import { Schedule, ScheduleSchema } from './models/Schedule';

export type ScheduleListParams = {
    dateFrom: string;
    dateTo: string;
};

export type ScheduleAssignParams = {
    /** The schedule id */
    scheduleId: string;
    /** The schedule date */
    scheduleDate: string;
    /** Work orders to assign to the schedule */
    workOrders: string[];
    /** The sortOrder of an existing item in the schedule - new items will be added above or below this item depending on the `poisiton` param - optional if blank then items will be appended last */
    sortOrder?: number;
    /** The id of the current workOrder with the provided `sortOrder` - for concurrency checking - only required if `sortOrder` is provided */
    sortOrderWorkOrderId?: string;
    /** Whether work orders are to be added above or below `sortOrder` - only required if `sortOrder` is provided */
    position?: 'ABOVE' | 'BELOW';
};

const scheduleApi = api.injectEndpoints({
    endpoints: build => ({
        scheduleList: build.query<Schedule[], ScheduleListParams>({
            query: params => ({
                url: '/schedule',
                method: 'POST',
                data: {
                    from: params.dateFrom,
                    to: params.dateTo,
                },
            }),
            transformResponse: (result: unknown) => z.array(ScheduleSchema).parse(result),
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                dispatch(scheduleApi.util.invalidateTags([ApiTagType.Schedule]));
                await queryFulfilled;
            },
            providesTags: [ApiTagType.Schedule],
        }),

        scheduleListWithWorkOrders: build.query<Schedule[], ScheduleListParams>({
            query: params => ({
                url: '/schedule/withworkorders',
                method: 'POST',
                data: {
                    from: params.dateFrom,
                    to: params.dateTo,
                },
            }),
            transformResponse: (result: unknown) => z.array(ScheduleSchema).parse(result),
            providesTags: [ApiTagType.Schedule],
        }),

        scheduleAssignWorkOrders: build.mutation<void, ScheduleAssignParams>({
            query: params => ({
                url: `/schedule/assign`,
                method: 'POST',
                data: params,
            }),
            invalidatesTags: [ApiTagType.Schedule],
        }),
    }),
});

export default scheduleApi;
