import React from 'react';
import { isEmpty } from 'utils/helpers';
import { SalesItemOptionsLabel, SalesItemOptionsOption } from '../../models/SalesItemOptions';

/** Display WorkstationWorkItemField value using unitSystem formatting */
export default function SalesItemDrawerFieldDisplay({
    field,
}: {
    field: SalesItemOptionsOption | SalesItemOptionsLabel;
}) {
    const value = field.value;
    if (isEmpty(value)) {
        return null;
    }
    if (Array.isArray(value)) {
        return <>{value.join(', ')}</>;
    }
    return <>{value}</>;
}
