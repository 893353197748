import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { Package } from 'features/dispatch/models/Package';
import { Shipment } from 'features/dispatch/models/Shipment';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import { WorkOrderItemDetail } from 'features/workOrders/models/WorkOrderItemDetail';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import ShipmentEditModal from '../ShipmentEditModal/ShipmentEditModal';
import ShipmentStatusBadge from '../ShipmentStatusBadge/ShipmentStatusBadge';
import ShipmentWorkItemsProgress from '../ShipmentWorkItemsProgress/ShipmentWorkItemsProgress';
import './ShipmentDetailModal.scss';

export default function ShipmentDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: Shipment;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [tab, setTab] = useUrlQueryState('tab', {
        allowedValues: ['packages', 'work-items'],
    });

    const dialogManager = useDialogManager();

    const edit = useCallback(() => {
        dialogManager.custom(ShipmentEditModal, { model });
    }, [dialogManager, model]);

    return (
        <MyModal
            className="ShipmentDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Shipments"
            header={
                <PageHeader
                    title="Shipment"
                    titleContext={model?.tuid}
                >
                    <MyButton
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Accent"
                        onClick={edit}
                    />
                </PageHeader>
            }
        >
            {model && (
                <>
                    <PropertyContainer layout="table">
                        <PropertyDisplay
                            label="Name"
                            value={model.name}
                        />

                        <PropertyDisplay
                            label="Date"
                            value={formatDateTimeRelative(model.date)}
                        />

                        <PropertyDisplay
                            label="Status"
                            value={<ShipmentStatusBadge shipment={model} />}
                        />

                        <PropertyDisplay
                            label="Shipment days"
                            value={model.shipmentDays}
                        />

                        <PropertyDisplay
                            label="Notes"
                            value={model.notes}
                        />
                    </PropertyContainer>

                    <MyTabs
                        className="ShipmentDetailModal__Tabs"
                        activeTab={tab}
                        setActiveTab={setTab}
                        tabs={[
                            {
                                name: 'packages',
                                label: `Packages (${model.context.packages.length})`,
                                content: <PackagesTab shipment={model} />,
                            },
                            {
                                name: 'work-items',
                                label: (
                                    <>
                                        Work items
                                        {model.context.assignedWorkOrderItems.length > 0 ? (
                                            <ShipmentWorkItemsProgress
                                                items={model.context.assignedWorkOrderItems}
                                            />
                                        ) : (
                                            <> (0)</>
                                        )}
                                    </>
                                ),
                                content: <WorkItemsTab shipment={model} />,
                            },
                        ]}
                    />
                </>
            )}
        </MyModal>
    );
}

const PACKAGE_COLUMNS = ColumnBuilder<Package>()
    .column({
        label: 'Package',
        key: 'tuid',
        isSortable: false,
        getValue: item => item.tuid,
    })
    .column({
        label: 'Description',
        key: 'description',
        isSortable: true,
        getValue: item => item.description,
    })
    .column({
        label: 'Dimensions',
        key: 'dimensions',
        isSortable: true,
        getValue: item => item.dimensions,
    })
    .column({
        label: 'Weight',
        key: 'weight',
        isSortable: true,
        getValue: item => item.weight,
    })
    .build();

function PackagesTab({ shipment }: { shipment: Shipment }) {
    const query = useMemo(
        () => ({
            data: shipment.context.packages,
            shipment,
            isLoading: false,
            isError: false,
            isFetching: false,
            refetch: () => {},
        }),
        [shipment],
    );
    return (
        <DataTable
            className="ShipmentDetailModal__ShippingItemsTab__DataTable"
            data={query.data}
            columns={PACKAGE_COLUMNS}
        />
    );
}

const WORK_ITEM_COLUMNS = ColumnBuilder<WorkOrderItemDetail>()
    .column({
        label: 'Work item',
        key: 'tuid',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        label: 'Work order',
        key: 'tuid',
        isSortable: true,
        getValue: item => item.context.workOrder.tuid,
    })
    .column({
        label: 'Description',
        key: 'description',
        isSortable: true,
        getValue: item => item.description,
    })
    .column({
        label: 'Status',
        key: 'workOrderItemState',
        width: '1px',
        whiteSpace: 'nowrap',
        isSortable: true,
        getValue: item => item.context.workOrderItemState,
        renderValue: val => (
            <div className="ShipmentDetailModal__WorkOrdersTab__WorkItemStatusCell">
                <WorkItemStatusBadge status={val} />
            </div>
        ),
    })
    .build();

function WorkItemsTab({ shipment }: { shipment: Shipment }) {
    const query = useMemo(
        () => ({
            data: shipment.context.assignedWorkOrderItems,
            shipment,
            isLoading: false,
            isError: false,
            isFetching: false,
            refetch: () => {},
        }),
        [shipment],
    );
    return (
        <DataTable
            className="ShipmentDetailModal__WorkItemsTab__DataTable"
            data={query.data}
            columns={WORK_ITEM_COLUMNS}
        />
    );
}
