import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import { Workstation } from 'features/workstations/models/Workstation';
import workstationsApi from 'features/workstations/workstations.api';
import { selectCurrentWorkstation } from 'features/workstations/workstations.slice';
import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import './WorkstationSelector.scss';

const FILTERS = FilterBuilder<Workstation>()
    .filter({
        label: 'Search',
        type: 'search',
        defaultValue: '',
        className: 'WorkstationSelector__SearchFilter',
        getFields: item => [item.name],
    })
    .build();

export default function WorkstationSelector() {
    const workstationsQuery = workstationsApi.useGetWorkstationsQuery();
    const [filteredStations, setFilteredStations] = useState<Workstation[]>();
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);

    const columns = useMemo(
        () =>
            ColumnBuilder<Workstation>()
                .column({
                    label: 'Name',
                    key: 'name',
                    getValue: item => item.name,
                    isSortable: true,
                    renderValue: (name, station) =>
                        station.id === currentWorkstation?.id ? `${name} (current)` : name,
                })
                .build(),
        [currentWorkstation?.id],
    );

    return (
        <div className="WorkstationSelector">
            <div className="WorkstationSelector__FilterBar">
                <DataTableFilters
                    data={workstationsQuery.data}
                    filters={FILTERS}
                    onChange={setFilteredStations}
                />
            </div>
            <DataTable
                className="WorkstationSelector__DataTable"
                isLoading={workstationsQuery.isLoading}
                isError={workstationsQuery.isError}
                data={filteredStations}
                rowLinkTo={s => `/workstations/${s.id}`}
                rowEndIcon={<Icons.ChevronRight />}
                rowIsHighlighted={s => s.id === currentWorkstation?.id}
                columns={columns}
                showHeader={false}
            />
        </div>
    );
}
