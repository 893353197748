import Icons from 'Icons';
import WorkStepsProgress from 'features/sales/components/WorkStepsProgress/WorkStepsProgress';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { computeWorkItemStepUiState } from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { WorkOrderItemDetail } from 'features/workOrders/models/WorkOrderItemDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import WorkItemStepHistoryModal from '../WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkItemStepStatusBadge from '../WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import './WorkItemStepList.scss';

export default function WorkItemStepList({
    workItem,
    className,
}: {
    workItem: WorkOrderItemDetail;
    className?: string;
}) {
    const dialogManager = useDialogManager();

    const showHistory = useCallback(
        (step: WorkItemStepBasic) => {
            dialogManager.custom(WorkItemStepHistoryModal, {
                model: step,
            });
        },
        [dialogManager],
    );

    const steps = workItem.context.workOrderItemSteps;
    const stepCount = steps?.length ?? 0;
    const completeCount =
        steps?.filter(s =>
            [
                WorkItemStepStatus.Completed,
                WorkItemStepStatus.CompletedLocked,
                WorkItemStepStatus.Skipped,
                WorkItemStepStatus.SkippedLocked,
            ].includes(s.status),
        ).length ?? 0;

    const completePercent = stepCount ? Math.round((completeCount / stepCount) * 100) : 0;

    if (!steps) {
        return null;
    }

    return (
        <div className={coalesceClassNames('WorkItemStepList', className)}>
            <div className="WorkItemStepList__Progress">
                <div className="WorkItemStepList__Progress__Counts">
                    <strong>
                        {stepCount} {stepCount === 1 ? 'step' : 'steps'}
                    </strong>
                    <div>
                        {completeCount} complete ({completePercent}%)
                    </div>
                </div>
                <div className="WorkItemStepList__Progress__Bar">
                    <WorkStepsProgress steps={steps} />
                </div>
            </div>

            <div className="WorkItemStepList__Steps">
                {steps?.map(step => {
                    const uiState = computeWorkItemStepUiState(
                        step.status,
                        step.context.canTransition,
                    );
                    return (
                        <a
                            key={step.id}
                            className="WorkItemStepList__Step"
                            onClick={() => showHistory(step)}
                        >
                            <div className="step-name">{step.context.workflowStep.name}</div>
                            <div className="workstation">
                                {step.context.workstationLatest?.name}
                            </div>
                            <WorkItemStepStatusBadge
                                uiState={uiState}
                                lastTransitionReason={step.lastTransitionReason ?? null}
                                badgeType="badge"
                            />
                            <Icons.ChevronRight className="chevron" />
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
