import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import { useBrandOptions } from 'features/customers/hooks/useBrandOptions';
import DocumentFileIcon from 'features/documents/components/DocumentFileIcon/DocumentFileIcon';
import { DocumentFriendlyTypeDisplay } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import './DocumentsTable.scss';

export default function DocumentsTable({
    data,
    isLoading,
    isError,
    onRefresh,
    isRefreshing,
    onRowClick,
    rowLinkTo,
    rowLinkToTarget,
    showFilterBar = false,
    showBrand = false,
}: {
    data?: DocumentFile[];
    isLoading?: boolean;
    isError?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    onRowClick?: (item: DocumentFile) => void;
    rowLinkTo?: (item: DocumentFile) => string;
    rowLinkToTarget?: string;
    showFilterBar?: boolean;
    showBrand?: boolean;
}) {
    const [filteredData, setFilteredData] = useState<DocumentFile[]>();
    const brandOptions = useBrandOptions();

    // highlight the currently open item id if any
    // this only work for the top-level page, not for documents inside orders
    const { documentId: activeItemId } = useParams();

    const columns = useMemo(
        () =>
            ColumnBuilder<DocumentFile>()
                .column({
                    key: 'name',
                    label: 'Document name',
                    isSortable: true,
                    getValue: item => item.name,
                    renderValue: (val, doc) => (
                        <>
                            <DocumentFileIcon type={doc.type} />
                            {val}
                        </>
                    ),
                })
                .column({
                    key: 'type',
                    label: 'Type',
                    isSortable: true,
                    getValue: item => item.type,
                    renderValue: val => DocumentFriendlyTypeDisplay.display(val),
                })
                .column(
                    showBrand && {
                        key: 'brand_id',
                        label: 'Brand',
                        isSortable: true,
                        getValue: item => item.brand_id,
                        emptyDash: true,
                        renderValue: val => brandOptions?.find(b => b.value === `${val}`)?.label,
                    },
                )
                .column({
                    key: 'created_at',
                    label: 'Created',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.created_at,
                    renderValue: val => formatDateTimeRelative(val),
                })
                .build(),
        [brandOptions, showBrand],
    );

    const filters = useMemo(
        () =>
            FilterBuilder<DocumentFile>()
                .filter({
                    type: 'search',
                    label: 'Search',
                    getFields: item => [item.name],
                    urlParam: 'search',
                })
                .filter({
                    type: 'select',
                    label: 'Type',
                    getField: item => item.type,
                    options: DocumentFriendlyTypeDisplay.options,
                    urlParam: 'type',
                })
                .filter({
                    type: 'autocomplete',
                    label: 'Brand',
                    getField: item => `${item.brand_id ?? ''}`,
                    options: brandOptions,
                    urlParam: 'brand',
                })
                .build(),

        [brandOptions],
    );

    return (
        <>
            {showFilterBar && (
                <div className="DocumentsTable__FilterBar">
                    <DataTableFilters
                        data={data}
                        onChange={setFilteredData}
                        filters={filters}
                    />
                </div>
            )}
            <DataTable
                className="DocumentsTable__DataTable"
                data={showFilterBar ? filteredData : data}
                isLoading={isLoading}
                isError={isError}
                columns={columns}
                onRefresh={onRefresh}
                isRefreshing={isRefreshing}
                rowIsHighlighted={item => `${item.id}` === activeItemId}
                emptyState="No documents found"
                rowLinkTo={rowLinkTo}
                rowLinkToTarget={rowLinkToTarget}
                onRowClick={onRowClick}
            />
        </>
    );
}
