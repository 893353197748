import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { InventoryMovementBatchTypeDisplay } from 'features/inventory/enums/InventoryMovementBatchType';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryItemMovement } from 'features/inventory/models/InventoryMovementBatch';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import InventoryMovementCreateDialog from '../InventoryMovementCreateDialog/InventoryMovementCreateDialog';
import './InventoryLocationDetailsMovements.scss';

const FILTERS = FilterBuilder<InventoryItemMovement>()
    .filter({
        label: 'Search',
        type: 'search',
        defaultValue: '',
        getFields: item => [item.batchNumber, item.movementType, item.notes],
    })
    .filter({
        label: 'Date',
        type: 'date',
        range: 'past',
        defaultValue: '',
        getField: item => item.date,
    })
    .filter({
        label: 'Type',
        type: 'select',
        defaultValue: '',
        options: InventoryMovementBatchTypeDisplay.options,
        getField: item => item.movementType,
    })
    .build();

const COLUMNS = ColumnBuilder<InventoryItemMovement>()
    .column({
        label: 'Date',
        key: 'date',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.date,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Batch',
        key: 'batchNumber',
        isSortable: true,
        getValue: item => item.batchNumber,
    })
    .column({
        label: 'Type',
        key: 'movementType',
        isSortable: true,
        getValue: item => InventoryMovementBatchTypeDisplay.display(item.movementType),
    })
    .column({
        label: 'Quantity',
        key: 'quantity',
        isSortable: true,
        getValue: item => item.quantity,
        renderValue: val =>
            val < 0 ? (
                <span className="negative">{val}</span>
            ) : (
                <span className="positive">+{val}</span>
            ),
    })
    .column({
        label: 'Notes',
        key: 'notes',
        isSortable: true,
        getValue: item => item.notes,
        renderValue: val => <span title={val}>{val}</span>,
    })
    .build();

export default function InventoryLocationDetailsMovements({
    inventoryId,
    locationId,
}: {
    inventoryId: string;
    locationId: string;
}) {
    const query = inventoryApi.useInventoryItemMovementsQuery(inventoryId || '', {
        skip: !inventoryId,
    });
    const [filteredData, setFilteredData] = useState<InventoryItemMovement[]>();

    // Filter the item movements to the relevant location
    const locationItemMovements = useMemo(() => {
        return query.data?.filter(im => im.locationId === locationId);
    }, [locationId, query.data]);

    const dialogManager = useDialogManager();
    const addMovement = useCallback(async () => {
        await dialogManager.custom(InventoryMovementCreateDialog, {
            inventoryId,
            defaultLocationId: locationId,
        });
    }, [dialogManager, inventoryId, locationId]);

    const locationQuery = inventoryApi.useLocationDetailQuery(locationId);

    return (
        <>
            <h2>Movements</h2>
            <div className="InventoryLocationDetailsMovements">
                <div className="InventoryLocationDetailsMovements__FilterBar">
                    <DataTableFilters
                        data={locationItemMovements}
                        filters={FILTERS}
                        onChange={setFilteredData}
                    >
                        {/* Don't show button if canStore = false for this location
                            The modal shouldn't be available at all, so this is just an extra layer of protection.
                        */}
                        {locationQuery.data?.data.canStore && (
                            <MyButton
                                className="InventoryLocationDetailsMovements__FilterBar__AddButton"
                                label="Move Stock"
                                size="large"
                                buttonType="Primary"
                                onClick={addMovement}
                            />
                        )}
                    </DataTableFilters>
                </div>
                {query.isLoading ? (
                    // Loading state
                    <div className="InventoryDetailsModal__TabLoadingPanel">
                        <MyLinearProgress />
                    </div>
                ) : query.isError ? (
                    // Error state
                    <ErrorContent />
                ) : (
                    <DataTable
                        className="InventoryLocationDetailsMovements__DataTable"
                        isLoading={query.isLoading}
                        isError={query.isError}
                        data={filteredData}
                        onRefresh={query.refetch}
                        zebra={true}
                        useFrontEndSorting={true}
                        isRefreshing={query.isFetching}
                        columns={COLUMNS}
                        emptyState="No stock movements found"
                    />
                )}
            </div>
        </>
    );
}
