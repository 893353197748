import { CircularProgress } from '@mui/material';
import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import MySelectInput from 'components/MySelectInput/MySelectInput';
import LocationType from 'features/inventory/enums/LocationType';
import inventoryApi, { InventorySearchParams } from 'features/inventory/inventory.api';
import { selectPrimaryLocationId, setPrimaryLocationId } from 'features/inventory/inventory.slice';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryTotals } from 'features/inventory/models/InventoryTotals';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import typescriptNaturalSort from 'typescript-natural-sort';
import './InventoryTable.scss';

export default function InventoryTable() {
    // highlight the currently open item id if any
    const { inventoryId: activeItemId } = useParams();

    // Select a warehouse for displaying totals
    const locationsQuery = inventoryApi.useLocationListQuery();
    const warehouseFilterOptions = useMemo(
        () =>
            locationsQuery.data?.data
                .filter(loc => loc.locationType === LocationType.Warehouse && !loc.parentLocationId)
                .map(loc => ({
                    label: loc.name,
                    value: loc.id,
                }))
                .sort((a, b) => typescriptNaturalSort(a.label, b.label)),
        [locationsQuery.data],
    );

    const inventoryGroupsQuery = inventoryApi.useInventoryGroupListWithAttributesQuery();
    const inventoryGroupOptions = useMemo(() => {
        if (!inventoryGroupsQuery.data) {
            return undefined;
        }
        const opts = inventoryGroupsQuery.data.data
            .map(g => ({
                label: g.name,
                value: g.name,
            }))
            .sort((a, b) => typescriptNaturalSort(a.label, b.label));
        opts.unshift({ label: '', value: '' });
        return opts;
    }, [inventoryGroupsQuery.data]);

    // Selected warehouse is handled by redux prop primaryLocationId in inventory slice
    const dispatch = useAppDispatch();
    const selectedWarehouseId = useAppSelector(selectPrimaryLocationId);

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<InventorySearchParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                })
                .criteria({
                    field: 'inventoryGroupId',
                    label: 'Group',
                    type: 'select',
                    options: inventoryGroupOptions,
                    defaultValue: '',
                })
                .build(),
        [inventoryGroupOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<InventorySearchParams>(criteriaFields, {
            uppercaseSortBy: true,
            defaultSort: { propertyKey: 'partNumber', direction: 'ASC' },
        });

    // Queries
    const query = inventoryApi.useInventorySearchQuery(queryParams);
    const totalsQuery = inventoryApi.useInventoryListTotalsQuery(
        {
            parentLocationId: selectedWarehouseId,
            tenantInventoryIds: query.data?.data.map(i => i.id) || [],
        },
        {
            skip:
                !selectedWarehouseId ||
                query.isFetching ||
                !query.data ||
                query.data.data.length === 0,
        },
    );

    /** Create a hash map of ids to totals */
    const totalsHash = useMemo(
        () =>
            totalsQuery.data?.reduce((hash, item) => {
                hash[item.tenantInventoryId] = item;
                return hash;
            }, {} as { [key: string]: InventoryTotals }),
        [totalsQuery.data],
    );

    const handleSortChanged = useCallback(
        (col: DataTableColumn<Inventory>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    const columns = useMemo(
        () =>
            ColumnBuilder<Inventory>()
                .column({
                    label: 'Part No',
                    key: 'partNumber',
                    isSortable: true,
                    defaultSort: 'ASC',
                    getValue: item => item.partNumber,
                })
                .column({
                    label: 'Supplier Part No',
                    key: 'supplierPartNumber',
                    isSortable: true,
                    getValue: item => item.supplierPartNumber,
                })
                .column({
                    label: 'Description',
                    key: 'description',
                    isSortable: true,
                    getValue: item => item.description,
                })
                .column({
                    label: 'Supplier',
                    key: 'tenantSupplierName',
                    isSortable: false,
                    getValue: item => item.tenantSupplierName,
                })
                .column({
                    label: 'Group',
                    key: 'inventoryGroupName',
                    isSortable: false,
                    getValue: item => item.inventoryGroupName,
                })
                .column({
                    label: 'On Hand',
                    key: 'quantityOnHand',
                    isSortable: false,
                    align: 'center',
                    getValue: item => totalsHash?.[item.id]?.quantityOnHand,
                    renderValue: val => {
                        if (totalsQuery.isFetching) {
                            return <CircularProgress size={12} />;
                        }
                        return val ?? '-';
                    },
                })
                .column({
                    label: 'Allocated',
                    key: 'quantityAllocated',
                    isSortable: false,
                    align: 'center',
                    getValue: item => totalsHash?.[item.id]?.quantityAllocated,
                    renderValue: val => {
                        if (totalsQuery.isFetching) {
                            return <CircularProgress size={12} />;
                        }
                        return val ?? '-';
                    },
                })
                .build(),
        [totalsHash, totalsQuery.isFetching],
    );

    return (
        <div className="InventoryTable">
            <div className="InventoryTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                >
                    {/* Warehouse selector for displaying stock level totals */}
                    {warehouseFilterOptions && (
                        <MySelectInput
                            className="InventoryTable__StockLevelSelectFilter"
                            label="Show stock levels for"
                            value={selectedWarehouseId}
                            options={warehouseFilterOptions}
                            handleInput={val => dispatch(setPrimaryLocationId(val))}
                        />
                    )}
                </DataTableCriteria>
            </div>

            <DataTable
                className="InventoryTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                onRefresh={() => {
                    query.refetch();
                    totalsQuery.refetch();
                }}
                rowLinkTo={item => item.id}
                rowIsHighlighted={item => item.id === activeItemId}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                isRefreshing={query.isFetching}
                columns={columns}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}
